import { useQuery } from '@tanstack/react-query'
import { getSdk, Pool } from '@/graphql/generated/graphql-request'
import { graphQLClient } from '@/services/graphql'
import { useQueryTime } from '@/hooks/useQueryTime'
import { createNotification, NotificationStatus, NotificationType } from '@/utils/notificationUtils'

export const usePools = () => {
  const {
    isLoading: loadingPools,
    data: allPools,
    isError,
    status,
  } = useQuery({
    queryKey: ['POOLS'],
    queryFn: async () =>
      getSdk(graphQLClient)
        .GetAllPools({
          pagination: {
            limit: 1000,
          },
        })
        .then((result) => result.getAllPools as Pool[])
        .catch((err) => {
          const message = err?.response?.errors[0]?.message ?? 'Error on GetAllPools'
          createNotification('Error on GetAllPools', message, NotificationType.SIMPLE, NotificationStatus.ERROR)
        }),
    placeholderData: [],
  })
  useQueryTime(`['POOLS']`, status)
  return {
    isError,
    loadingPools,
    allPools: allPools ?? [],
  }
}
