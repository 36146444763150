import { flexRender, getCoreRowModel, getPaginationRowModel, useReactTable } from '@tanstack/react-table'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { Button } from '@/components/ui/button'
import { useAllAssets } from '@/hooks/useAsset'
import { useModulePositions } from '@/hooks/useModulePositions'
import { tableColumnsModulePositions } from '@/components/administration/TableColumnsModulePositions'
import { Balance } from '@/graphql/generated/graphql-request'
import { useMemo } from 'react'

export type AggregatedModulePositions = {
  assetID: string
  balance: Balance
  supplied: Balance
  borrowed: Balance
  virtual: Balance
}

export function TableModulePositions() {
  const { modulePositions } = useModulePositions()
  const { allAssets } = useAllAssets()
  const columns = tableColumnsModulePositions

  // Step 1: Aggregate data in a single loop
  const aggregatedData = useMemo(() => {
    const data: Record<string, AggregatedModulePositions> = {}

    modulePositions.balance.forEach((item) => {
      if (!data[item.assetID]) {
        data[item.assetID] = {
          assetID: item.assetID,
          balance: item,
          supplied: { assetID: item.assetID, amount: '0', sortAmount: 0 },
          borrowed: { assetID: item.assetID, amount: '0', sortAmount: 0 },
          virtual: { assetID: item.assetID, amount: '0', sortAmount: 0 },
        }
      } else {
        data[item.assetID].balance = item
      }
    })

    modulePositions.supplied.forEach((item) => {
      if (!data[item.assetID]) {
        data[item.assetID] = {
          assetID: item.assetID,
          balance: { assetID: item.assetID, amount: '0', sortAmount: 0 },
          supplied: item,
          borrowed: { assetID: item.assetID, amount: '0', sortAmount: 0 },
          virtual: { assetID: item.assetID, amount: '0', sortAmount: 0 },
        }
      } else {
        data[item.assetID].supplied = item
      }
    })

    modulePositions.borrowed.forEach((item) => {
      if (!data[item.assetID]) {
        data[item.assetID] = {
          assetID: item.assetID,
          balance: { assetID: item.assetID, amount: '0', sortAmount: 0 },
          supplied: { assetID: item.assetID, amount: '0', sortAmount: 0 },
          borrowed: item,
          virtual: { assetID: item.assetID, amount: '0', sortAmount: 0 },
        }
      } else {
        data[item.assetID].borrowed = item
      }
    })

    modulePositions.virtual.forEach((item) => {
      if (!data[item.assetID]) {
        data[item.assetID] = {
          assetID: item.assetID,
          balance: { assetID: item.assetID, amount: '0', sortAmount: 0 },
          supplied: { assetID: item.assetID, amount: '0', sortAmount: 0 },
          borrowed: { assetID: item.assetID, amount: '0', sortAmount: 0 },
          virtual: item,
        }
      } else {
        data[item.assetID].virtual = item
      }
    })

    return Object.values(data)
  }, [modulePositions])

  const getAsset = useMemo(
    () => (assetID: string) => {
      return allAssets.find((asset) => asset.uid === assetID)
    },
    [allAssets],
  )

  const table = useReactTable({
    data: aggregatedData,
    columns,
    enableColumnResizing: true,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    meta: {
      getAsset,
    },
  })

  return (
    <div className='w-full panel dark:bg-card flex flex-col h-full'>
      <div className='relative flex justify-between items-center'>
        <h1 className={'dark:text-white text-lg pb-1 font-semibold'}>Positions</h1>
      </div>
      <Table>
        <TableHeader className='text-sm'>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow className='p-1 ' key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHead
                  className='dark:bg-dark-darker border-b border-b-dark'
                  key={header.id}
                  style={{ width: header.getSize() }}
                >
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow className='p-1' key={row.id} data-state={row.getIsSelected() && 'selected'}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell className={`border-b border-dark`} key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow className='border p-1'>
              <TableCell colSpan={columns.length} className='text-center'>
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      <div className='flex items-center justify-end space-x-2 py-4'>
        <Button variant='outline' size='sm' onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
          Previous
        </Button>
        <div className={'text-xs'}>
          {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
        </div>
        <Button variant='outline' size='sm' onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
          Next
        </Button>
      </div>
    </div>
  )
}
