import { useQuery } from '@tanstack/react-query'
import { getSdk } from '@/graphql/generated/graphql-request'
import { graphQLClient } from '@/services/graphql'
import { createNotification, NotificationStatus, NotificationType } from '@/utils/notificationUtils'
import { useQueryTime } from '@/hooks/useQueryTime'

export const useAppEnv = () => {
  const {
    isLoading: loadingAppEnv,
    data: appEnv,
    status,
  } = useQuery({
    queryKey: ['APP_ENV'],
    queryFn: async () =>
      getSdk(graphQLClient)
        .GetAppEnv()
        .then((result) => result.getAppEnv)
        .catch((err) => {
          const message = err?.response?.errors[0]?.message ?? 'Error on GetAppEnv'
          createNotification('Error on GetAppEnv', message, NotificationType.SIMPLE, NotificationStatus.ERROR)
        }),
    placeholderData: '',
  })
  useQueryTime(`['APP_ENV'}]`, status)
  return {
    loadingAppEnv,
    appEnv: appEnv ?? '',
  }
}
