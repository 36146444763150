import {
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  Row,
  useReactTable,
} from '@tanstack/react-table'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { Button } from '@/components/ui/button'
import { useEffect, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import {
  getSdk,
  StartTraderBotMutationVariables,
  StopTraderBotMutationVariables,
  TraderBot,
  User,
} from '@/graphql/generated/graphql-request'
import { graphQLClient } from '@/services/graphql'
import { createNotification, NotificationStatus, NotificationType } from '@/utils/notificationUtils'
import { queryClient } from '@/services/api'
import { useTraderBots } from '@/hooks/useTraderBots'
import { tableColumnsTraderBots } from '@/components/administration/TableColumnsTraderBots'
import { HoverCard, HoverCardContent, HoverCardTrigger } from '@/components/ui/hover-card'
import { TraderBotCard } from '@/components/administration/TraderBotCard'
import { CreateTraderBotModal } from '@/components/administration/CreateTraderBotModal'

export function TraderBots() {
  const { traderBots } = useTraderBots()
  const [selectedTraderBot, setSelectedTraderBot] = useState<string | null>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const columns = tableColumnsTraderBots

  const startTraderBotMutation = useMutation({
    mutationFn: (params: StartTraderBotMutationVariables) =>
      getSdk(graphQLClient)
        .StartTraderBot(params)
        .then(() => {
          createNotification(
            'Start Trader Bot',
            'Trader Bot started successfully',
            NotificationType.SIMPLE,
            NotificationStatus.SUCCESS,
          )
        })
        .catch((err) => {
          const message = err?.response?.errors[0]?.message ?? 'Could not start Trader Bot'
          createNotification('Error on Start Trader Bot', message, NotificationType.SIMPLE, NotificationStatus.ERROR)
        }),

    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['TRADER_BOTS'] }).finally()
    },
  })

  const stopTraderBotMutation = useMutation({
    mutationFn: (params: StopTraderBotMutationVariables) =>
      getSdk(graphQLClient)
        .StopTraderBot(params)
        .then(() => {
          createNotification(
            'Stop Trader Bot',
            'Trader Bot stopped successfully',
            NotificationType.SIMPLE,
            NotificationStatus.SUCCESS,
          )
        })
        .catch((err) => {
          const message = err?.response?.errors[0]?.message ?? 'Could not stop Trader Bot'
          createNotification('Error on Stop Trader Bot', message, NotificationType.SIMPLE, NotificationStatus.ERROR)
        }),

    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['TRADER_BOTS'] }).finally()
    },
  })

  // Custom function to filter by multiple columns
  const globalFilterFunction = (row: Row<TraderBot>, columnId: string, filterValue: string) => {
    const botID = row.original.botID || ''
    const name = row.original.name || ''
    const orderBookID = row.original.orderBookID || ''

    return (
      botID.toLowerCase().includes(filterValue.toLowerCase()) ||
      name.toLowerCase().includes(filterValue.toLowerCase()) ||
      orderBookID.toLowerCase().includes(filterValue.toLowerCase())
    )
  }

  const table = useReactTable({
    data: traderBots,
    columns,
    enableColumnResizing: true,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    meta: {
      editBot: (botID: string) => {
        setSelectedTraderBot(botID)
        setIsOpen(true)
      },
      startStopBot: (botID: string, start: boolean) => {
        if (start) {
          startTraderBotMutation.mutate({ botId: botID })
        } else {
          stopTraderBotMutation.mutate({ botId: botID })
        }
      },
    },
    onColumnFiltersChange: setColumnFilters, //<- important
    getFilteredRowModel: getFilteredRowModel(), //<- important
    state: {
      columnFilters, //<- important
    },
    globalFilterFn: globalFilterFunction, //<- important
  })

  useEffect(() => {
    if (!isOpen) {
      setSelectedTraderBot(null)
    }
  }, [isOpen])

  const setFilter = (value: string) => {
    table.setGlobalFilter(value)
  }

  return (
    <div className='w-full panel dark:bg-card flex flex-col h-full'>
      <div className={'flex flex-row gap-2 justify-between items-center mb-2'}>
        <h1 className={'dark:text-white text-lg pb-1 font-semibold'}>Trader Bots</h1>
        <div className={'flex flex-row gap-2'}>
          <div className='relative flex justify-between items-center'>
            <div className='relative flex justify-end'>
              <input
                placeholder={'Search... BotID, Name, OrderBookID'}
                onChange={(e) => setFilter(e.target.value)}
                className='text-sm my-2 w-[340px] relative rounded-sm shadow-sm form-input peer placeholder:tracking-widest border valid:border-dark text-right outline-none focus:ring-0 focus:ring-opacity-0 pl-2 !bg-black'
              />
              <span className='absolute left-2 top-1/2 transform -translate-y-1/2'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                  className='w-6 h-6 text-white-dark'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={1}
                    d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'
                  />
                </svg>
              </span>
            </div>
          </div>
          <div className={'flex items-center'}>
            <CreateTraderBotModal traderBotID={selectedTraderBot} isOpen={isOpen} setIsOpen={setIsOpen} />
          </div>
        </div>
      </div>

      <Table>
        <TableHeader className='text-sm'>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow className='p-1 ' key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead
                    className='dark:bg-dark-darker border-b border-b-dark'
                    key={header.id}
                    style={{
                      width: header.getSize(),
                    }}
                  >
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                )
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow className='p-1' key={row.id} data-state={row.getIsSelected() && 'selected'}>
                {row.getVisibleCells().map((cell, index) => {
                  const showCard = index < row.getVisibleCells().length - 3
                  return (
                    <TableCell className={`border-b border-dark`} key={cell.id}>
                      {showCard ? (
                        <HoverCard>
                          <HoverCardTrigger className={'w-full'}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </HoverCardTrigger>
                          <HoverCardContent>
                            <TraderBotCard botID={row.original.botID} />
                          </HoverCardContent>
                        </HoverCard>
                      ) : (
                        flexRender(cell.column.columnDef.cell, cell.getContext())
                      )}
                    </TableCell>
                  )
                })}
              </TableRow>
            ))
          ) : (
            <TableRow className='border p-1'>
              <TableCell colSpan={columns.length} className='text-center'>
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      <div className='flex items-center justify-end space-x-2 py-4'>
        <Button variant='outline' size='sm' onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
          Previous
        </Button>
        <div className={'text-xs'}>
          {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
        </div>
        <Button variant='outline' size='sm' onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
          Next
        </Button>
      </div>
    </div>
  )
}
