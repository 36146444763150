import { useEffect, useRef, useState } from 'react'
import styled, { css, IStyledComponent } from 'styled-components'
import type { TvWidget } from '@/constants/tvchart'
import { useTradingView } from '@/hooks/tradingView'
import { layoutMixins } from '@/styles/layoutMixins'
import { LoadingSpace } from '@/components/Loading/LoadingSpinner'
import { BondDetailsPanel } from '@/components/bondDetails/BondDetailsPanel'
import { useSearchParams } from 'react-router-dom'
import { AppTheme } from '@/utils/tradingview'

interface StoredObject {
  paneProperties: {
    background: string
    backgroundType: string
  }
}

export const TvChart = ({ candlesOnly }: { candlesOnly?: boolean }) => {
  const [params] = useSearchParams()

  const appTheme = params.get('theme') === 'light' ? AppTheme.Light : AppTheme.Dark
  const [isChartReady, setIsChartReady] = useState(false)
  const [showBondDetails, setShowBondDetails] = useState<boolean>(false)
  const tvWidgetRef = useRef<TvWidget | null>(null)
  const tvWidget = tvWidgetRef.current
  const isWidgetReady = tvWidget?._ready

  const displayButtonRef = useRef<HTMLElement | null>(null)
  const displayButton = displayButtonRef.current

  useTradingView({ candlesOnly, tvWidgetRef, displayButtonRef, setIsChartReady, setShowBondDetails })
  // useChartMarketAndResolution({
  //   tvWidget,
  //   isWidgetReady,
  //   savedResolution: savedResolution as ResolutionString | undefined,
  // })
  // const { chartLines } = useChartLines({ tvWidget, displayButton, isChartReady })
  // useTradingViewTheme({ tvWidget, isWidgetReady, chartLines })

  // TODO - Check better way to do this change of background on TradingView chart
  useEffect(() => {
    // Step 1: Retrieve the stored object
    const storedString = localStorage.getItem('tradingview.chartproperties')
    if (!storedString) return

    // Step 2: Parse the string into an object
    const storedObject: StoredObject = JSON.parse(storedString)

    // Step 3: Change the paneProperties.background property to white
    storedObject.paneProperties.background = appTheme === AppTheme.Light ? 'white' : 'rgba(11, 11, 14, 1)'
    storedObject.paneProperties.backgroundType = 'solid'

    // Step 4: Convert the object back into a string
    const updatedObject = JSON.stringify(storedObject)

    // Step 5: Store the updated string back to localStorage
    localStorage.setItem('tradingview.chartproperties', updatedObject)

    if ((window as any).ReactNativeWebView) {
      ;(window as any).ReactNativeWebView.postMessage(isChartReady ? 'loaded' : 'loading')
    }
  }, [isChartReady])

  return (
    <Styled.PriceChart $isChartReady={isChartReady}>
      {showBondDetails && <BondDetailsPanel setShowBondDetails={setShowBondDetails} appTheme={appTheme} />}
      {!isChartReady && !showBondDetails && (
        <LoadingSpace id='tv-chart-loading' className={appTheme === AppTheme.Light ? 'bg-white' : ''} />
      )}
      <div id='tv-price-chart' className={candlesOnly ? '' : 'panel'} />
    </Styled.PriceChart>
  )
}

const Styled: Record<string, IStyledComponent<'web', FixMe>> = {}

Styled.PriceChart = styled.div<{ $isChartReady?: boolean }>`
  ${layoutMixins.stack}

  height: 100%;

  #tv-price-chart {
    ${({ $isChartReady }) =>
      !$isChartReady &&
      css`
        filter: blur(3px);
        translate: 0 0 1rem;
        opacity: 0;
      `};

    @media (prefers-reduced-motion: no-preference) {
      transition: 0.2s var(--ease-out-expo);
    }
  }
`
