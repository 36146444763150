import { initializeApp } from 'firebase/app'
import {
  createUserWithEmailAndPassword,
  getAuth,
  connectAuthEmulator,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
  UserCredential,
} from 'firebase/auth'
import { updateProfile } from '@firebase/auth'
import { firebaseConfig, Environment } from '@/configs/firebase'
import { doc, getFirestore, setDoc } from 'firebase/firestore'
import * as Sentry from '@sentry/react'
import { SignMessageMutateAsync } from 'wagmi/query'

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)

if (Environment.environment == 'emulator') {
  console.log('Running on firestore emulator', Environment)
  connectAuthEmulator(auth, Environment.firebaseAuthEmulatorHost)
}

const db = getFirestore(app)
export const firebaseRegisterUser = async (name: string, email: string, password: string) => {
  try {
    const userCredentials = await createUserWithEmailAndPassword(auth, email, password)
    if (userCredentials) {
      await updateProfile(userCredentials.user, { displayName: name })
      return { userCredentials, error: null }
    }
  } catch (error: any) {
    Sentry.captureMessage('firebaseRegisterUser', {
      extra: {
        defaultLayout: true,
        location: location.pathname,
        error: error,
      },
    })
    return { userCredentials: null, error }
  }
}

export const firebaseUpdateUser = async (userData: Record<string, unknown>) => {
  try {
    if (auth.currentUser) {
      const userRef = doc(db, 'users', auth.currentUser.uid)
      await setDoc(userRef, userData, { merge: true })
    }
  } catch (error) {
    console.log('firebaseUpdateUser error', error)
    Sentry.captureMessage('firebaseUpdateUser', {
      extra: {
        defaultLayout: true,
        location: location.pathname,
        error: error,
      },
    })
  }
}

export const signInWithGoogle = async () => {
  try {
    const provider = new GoogleAuthProvider()
    const userCredentials = await signInWithPopup(auth, provider)
    if (!userCredentials.user) throw new Error(`Couldn't sign in with Google`)
    if (userCredentials.user.metadata.creationTime === userCredentials.user.metadata.lastSignInTime) {
      return userCredentials
    }
  } catch (error) {
    Sentry.captureMessage('signInWithGoogle', {
      extra: {
        defaultLayout: true,
        location: location.pathname,
        error: error,
      },
    })
  }
}

// Signing in with Ethereum wallet

// Background: Firebase does not support signing in with Eth wallet
// Work Around: using 0x......@dora.co as email, and base64 signed message of "Sign In to Dora" as password to sign in to firebase with email and password

export const signInWithWallet = async (signMessageAsync: SignMessageMutateAsync, address?: string) => {
  if (!address) {
    return
  }
  let signed = ''
  try {
    signed = await signMessageAsync({
      message: 'Sign In to Dora',
    })
    // Attempt to sign in
    const userCredential: UserCredential | undefined = await signInWithEmailAndPassword(
      auth,
      `${address}@dora.co`,
      signed,
    )

    if (userCredential) {
      return userCredential
    } else {
      throw new Error('auth/invalid-credential')
    }
  } catch (error: any) {
    // User does not exist
    if (error.message.includes('auth/invalid-credential')) {
      // Create user on firebase
      const result = await firebaseRegisterUser(address, `${address}@dora.co`, signed)
      if (!result || result.error) {
        throw new Error(result?.error)
      }

      const { userCredentials } = result

      if (userCredentials) {
        return userCredentials
      } else {
        throw new Error(error.message)
      }
    }
    if (error.message.includes('User rejected the request')) {
      throw new Error('User rejected the request')
    }
    throw new Error(error.message)
  }
}
