import { createSlice } from '@reduxjs/toolkit'
import { NotificationItem } from '@/utils/notificationUtils'
import { Order, OrderState } from '@/graphql/generated/graphql-request'

// Step 2: Define the initial state
const initialState = {
  notifications: [] as NotificationItem[],
}

// Step 3: Create the slice
const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    addNotification(state, action: { payload: NotificationItem }) {
      //validate if notification with same id already exists, replace if it does or add if it doesn't
      const notification = action.payload
      const index = state.notifications.findIndex((n) => n.id === notification.id)
      if (index >= 0) {
        state.notifications[index] = notification
      } else {
        state.notifications.push(notification)
      }
    },
    updatePartialNotification(state, action: { payload: NotificationItem }) {
      const notification = action.payload
      const newOrder = notification.params.order as Order

      //validate if notification with same id already exists, replace if it does or add if it doesn't
      const index = state.notifications.findIndex((n) => {
        const params = n.params
        if (!params || !params.order) return false

        const oldOrder = params.order as Order
        return oldOrder.state === OrderState.Partial && oldOrder.orderID === newOrder.orderID
      })
      if (index >= 0) {
        state.notifications[index] = notification
      } else {
        state.notifications.push(notification)
      }
    },
    deleteNotification(state, action: { payload: string }) {
      //validate if notification with same id already exists, replace if it does or add if it doesn't
      const id = action.payload
      const index = state.notifications.findIndex((n) => n.id === id)
      if (index >= 0) {
        state.notifications.splice(index, 1)
      }
    },
    clearNotifications(state) {
      state.notifications = []
    },
  },
})

// Step 4: Export the actions and reducer
export const { addNotification, updatePartialNotification, deleteNotification, clearNotifications } =
  notificationSlice.actions
export default notificationSlice.reducer
