const balanceSuffixes = {
  trillions: 'T',
  billions: 'B',
  millions: 'M',
  thousands: 'K',
}

export function countDecimalPlaces(number: number | string) {
  const num = Number(number)
  if (Number.isNaN(num)) return 0
  if (Number.isInteger(num)) return 0
  let e = 1,
    p = 0
  while (Math.round(num * e) / e !== num) {
    e *= 10
    p++
  }
  return p
}

/**
 * Adjusts the price of an order to the nearest tick size.
 *
 * @param {number} price - The price that needs to be adjusted.
 * @param {number} tickSize - The tick size to which the price needs to be adjusted.
 * @returns {number} - The adjusted price.
 *
 * @example
 * // Case where the absolute difference between price and nearestMultiple is greater than or equal to tolerance
 * // Assuming we have an order with a price of 1003 and a tick size of 0.002
 * let order = { price: 1003 };
 * let tickSize = 0.002;
 * let adjustedPrice = adjustPriceToTickSize(order, tickSize);
 * console.log(adjustedPrice); // Outputs: 1002
 *
 * // Case where the absolute difference between price and nearestMultiple is less than tolerance
 * // Assuming we have an order with a price of 1002 and a tick size of 0.002
 * order = { price: 1002 };
 * tickSize = 0.002;
 * adjustedPrice = adjustPriceToTickSize(order, tickSize);
 * console.log(adjustedPrice); // Outputs: 1002
 */
export function adjustPriceToTickSize(price: number, tickSize: number): number {
  const tolerance = 0.000001 // tolerance threshold
  const tickDecimals = countDecimalPlaces(tickSize)
  const tickNumber = tickSize * Math.pow(10, tickDecimals)
  const nearestMultiple = Math.round(price / tickNumber) * tickNumber
  const remainder = Math.abs(nearestMultiple - price)

  if (Math.abs(price - nearestMultiple) >= tolerance) {
    return price - remainder
  }

  return price
}

export const getUSDValue = (value = 0): number => {
  if (Math.abs(value) < 1.0e-2) return 0
  return value
}

export const formatValueWithSuffix = (value: number, addSuffix = true): string => {
  //convert to number in case it is a string
  const n = +value
  try {
    if (n === 0 || n === null) {
      return '0.000'
    }
    if (n < 0.1) return n.toFixed(4)
    if (n < 1e3) return n.toFixed(2)
    if (n >= 1e3 && n < 1e6) return (n / 1e3).toFixed(3) + (addSuffix ? balanceSuffixes.thousands : '')
    if (n >= 1e6 && n < 1e9) return (n / 1e6).toFixed(3) + (addSuffix ? balanceSuffixes.millions : '')
    if (n >= 1e9 && n < 1e12) return (n / 1e9).toFixed(3) + (addSuffix ? balanceSuffixes.billions : '')
    if (n >= 1e12) return (n / 1e12).toFixed(3) + (addSuffix ? balanceSuffixes.trillions : '')
  } catch (error) {
    console.log('ERROR:', error)
  }

  return '0.00'
}

/**
 * Formats a number as currency using the specified locale and adds the currency symbol.
 * @param number - The number to format.
 * @param decimals - The number of decimal places to show in the formatted number.
 * @param locale - The locale to use for formatting (e.g., 'en-US', 'de-DE', etc.).
 * @param currency - The ISO currency code (e.g., 'USD', 'EUR', 'JPY', etc.).
 * @param showSymbol - A boolean value that determines whether to show the currency symbol or not.
 * @returns The formatted number as a string with the currency symbol.
 */
export function formatNumber(
  number: number | string | undefined,
  decimals?: number | undefined,
  locale?: string,
  currency?: string,
  showSymbol?: boolean,
): string {
  locale = locale || navigator.language
  currency = currency || 'USD'
  decimals = decimals !== undefined ? decimals : 2

  const formattedNumber = Number.isNaN(Number(number)) ? 0 : Number(number)

  showSymbol = showSymbol !== undefined ? showSymbol : false

  return new Intl.NumberFormat(locale, {
    style: showSymbol ? 'currency' : 'decimal',
    currency,
    currencyDisplay: 'symbol',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  })
    .format(formattedNumber)
    .trim()
}

/**
 * Converts a formatted number string back to a number.
 * @param numberString - The formatted number string to convert.
 * @returns The number.
 */
export function removeFormatNumber(numberString: string): string {
  // Remove any non-numeric characters except the decimal point
  const sanitizedString = numberString.replace(/[^0-9.]/g, '')

  // Convert the sanitized string to a number
  return (Number.isNaN(sanitizedString) ? 0 : Number(sanitizedString)).toString()
}
