import { gql } from 'graphql-request'

export const ORDERBOOK_SUBSCRIPTION = gql`
  subscription OrderBookUpdated($orderBookId: String!) {
    orderBookUpdated(orderBookID: $orderBookId) {
      sells {
        price
        size
      }
      buys {
        price
        size
      }
    }
  }
`
