import { useQuery } from '@tanstack/react-query'
import { getSdk, PoolOrdersSummary } from '@/graphql/generated/graphql-request'
import { graphQLClient } from '@/services/graphql'
import { useQueryTime } from '@/hooks/useQueryTime'
import { createNotification, NotificationStatus, NotificationType } from '@/utils/notificationUtils'

export const usePoolOrdersSummary = (orderbookID: string, candlesOnly?: boolean) => {
  const {
    isLoading: loadingPools,
    data: poolOrdersSummary,
    status,
  } = useQuery({
    enabled: !candlesOnly,
    queryKey: ['POOLS_ORDERS_SUMMARY', orderbookID],
    queryFn: async () =>
      getSdk(graphQLClient)
        .GetPoolOrdersSummary({
          orderBookId: orderbookID,
        })
        .then((result) => result?.getPoolOrdersSummary as PoolOrdersSummary)
        .catch((err) => {
          const message = err?.response?.errors[0]?.message ?? 'Error on GetPoolOrdersSummary'
          createNotification(
            'Error on GetPoolOrdersSummary',
            message,
            NotificationType.SIMPLE,
            NotificationStatus.ERROR,
          )
        }),
  })
  useQueryTime(`['POOLS_ORDERS_SUMMARY', ${orderbookID}]`, status)

  return {
    loadingPools,
    poolOrdersSummary,
  }
}
