import { useAccount, useSwitchChain } from 'wagmi'
import { useEffect, useState } from 'react'
import { USDC, USDCDecimals } from '@/configs/tokens'
import { formatUnits } from 'viem'
import { formatNumber } from '@/utils/numberUtils'

export const EthereumButton = ({
  setIsDepositOpen,
  setIsWithdrawOpen,
  isConnected,
  address,
  usdcBalanceResult,
  doraUsdcBalanceResult,
}: {
  setIsDepositOpen: (o: boolean) => void
  setIsWithdrawOpen: (o: boolean) => void
  isConnected: boolean
  address?: `0x${string}`
  usdcBalanceResult: any
  doraUsdcBalanceResult: any
}) => {
  const { chain } = useAccount()
  const { switchChain } = useSwitchChain()
  const [selectedChain, setSelectedChain] = useState<number>()

  useEffect(() => {
    if (chain?.id && selectedChain && selectedChain !== chain?.id) {
      setTimeout(() => {
        switchChain({ chainId: selectedChain })
      }, 1000)
    }
  }, [selectedChain, chain?.id, isConnected])

  return (
    <>
      {isConnected ? (
        <>
          <div className={'w-full flex flex-row justify-center items-center gap-2'}>
            {/*@ts-ignore*/}
            <w3m-button />
          </div>
          <div className='flex justify-between items-end my-4'>
            <div className='flex flex-col items-start'>
              <div className='flex gap-2'>
                <span className='text-sm'>USDC Balance</span>
                <img
                  alt='ethereumIcon'
                  className={'w-[20px] h-[20px] p-0 m-0'}
                  src={chain?.id === 1 ? '/assets/images/wallet/ethereum.svg' : '/assets/images/wallet/arbitrum.svg'}
                />
              </div>
              <span className='text-xl text-white'>
                {formatNumber(
                  formatUnits(usdcBalanceResult?.data?.[0] || '', (USDC[chain?.id || 1] || USDC[1]).decimals),
                  (USDC[chain?.id || 1] || USDC[1]).decimals,
                )}
              </span>
            </div>
            <button
              type='button'
              className='w-28 btn bg-gradient-to-r from-secondary to-primary hover:from-primary hover:to-secondary text-white border-0 uppercase shadow-[0_10px_20px_-10px_rgba(67,97,238,0.44)] '
              onClick={() => setIsDepositOpen(true)}
            >
              Deposit
            </button>
          </div>
          <div className='flex justify-between items-end my-4'>
            <div className='flex flex-col items-start'>
              <div className='flex gap-2'>
                <span className='text-sm'>USDC Balance</span>
                <img alt='ethereumIcon' className={'w-[20px] h-[20px] p-0 m-0'} src={'/favicon.png'} />
              </div>
              <span className='text-xl text-white'>
                {formatNumber(formatUnits(doraUsdcBalanceResult?.amount || '', USDCDecimals), USDCDecimals)}
              </span>
            </div>
            <button
              type='button'
              className='w-28 btn bg-gradient-to-r from-secondary to-primary hover:from-primary hover:to-secondary text-white border-0 uppercase shadow-[0_10px_20px_-10px_rgba(67,97,238,0.44)] '
              onClick={() => setIsWithdrawOpen(true)}
            >
              Withdraw
            </button>
          </div>
        </>
      ) : (
        <>
          <div className={'w-full flex flex-row justify-center items-center gap-2 mb-6'}>
            {!isConnected && (
              <img
                alt='ethereumIcon'
                className={'w-[32px] h-[32px] p-0 m-0'}
                src={'/assets/images/wallet/ethereum.svg'}
              />
            )}
            <div onClick={() => setSelectedChain(1)}>
              {/*@ts-ignore*/}
              <w3m-button />
            </div>
          </div>
          <div className={'w-full flex flex-row justify-center items-center gap-2'}>
            {!isConnected && (
              <img
                alt='arbitrumIcon'
                className={'w-[32px] h-[32px] p-0 m-0'}
                src={'/assets/images/wallet/arbitrum.svg'}
              />
            )}
            <div onClick={() => setSelectedChain(42161)}>
              {/*@ts-ignore*/}
              <w3m-button />
            </div>
          </div>
        </>
      )}
    </>
  )
}
