import { useQuery } from '@tanstack/react-query'
import { getSdk, OrdersSizeResponse } from '@/graphql/generated/graphql-request'
import { graphQLClient } from '@/services/graphql'
import { createNotification, NotificationStatus, NotificationType } from '@/utils/notificationUtils'
import { useMemo } from 'react'
import { orderBy } from 'lodash'
import { useOrderBookSubscription } from '@/hooks/orderBook/useOrderBookSubscription'

export const useOrderBookOrders = (orderBookId: string, candlesOnly?: boolean) => {
  useOrderBookSubscription(orderBookId)
  const { isLoading, data } = useQuery({
    enabled: !candlesOnly,
    queryKey: ['ORDER_BOOK_ORDERS', orderBookId],
    queryFn: async () =>
      getSdk(graphQLClient)
        .OrderBookOpenOrders({ orderBookId: orderBookId })
        .then((result) => result.orderBookOpenOrders as OrdersSizeResponse)
        .catch((err) => {
          const message = err?.response?.errors[0]?.message ?? 'Error on OrderBookOpenOrders'
          createNotification('Error on OrderBookOpenOrders', message, NotificationType.SIMPLE, NotificationStatus.ERROR)
        }),
  })

  const orderedSells = useMemo(() => {
    return orderBy(data?.sells, 'price', 'asc') ?? []
  }, [JSON.stringify(data?.sells)])

  const orderedBuys = useMemo(() => {
    return orderBy(data?.buys, 'price', 'desc') ?? []
  }, [JSON.stringify(data?.buys)])

  return { isLoading, buys: orderedBuys, sells: orderedSells }
}
