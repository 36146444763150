import { OrderBook, OrdersSize } from '@/graphql/generated/graphql-request'
import { OrderBookLine } from '@/types/OrderBook'
import { useMemo } from 'react'
import { usePoolOrdersSummary } from '@/hooks/usePoolOrdersSummary'
import { useOrderBookID } from '@/hooks/useOrderBookID'
import { useOrderBooks } from '@/hooks/useOrderBooks'
import * as Sentry from '@sentry/react'
import { useOrderBookOrders } from '@/hooks/orderBook/useOrderBookOrders'

export const useCurrentOrderBook = (candlesOnly?: boolean) => {
  const { orderBookId } = useOrderBookID(candlesOnly)
  const { orderBooks, loadingOrderBooks, error } = useOrderBooks()
  const currentMarket = orderBooks.find((orderbook) => orderbook.orderBookID === orderBookId)
  const { poolOrdersSummary } = usePoolOrdersSummary(orderBookId, candlesOnly)
  const { buys, sells } = useOrderBookOrders(orderBookId, candlesOnly)

  if (!currentMarket) {
    Sentry.captureMessage(`OrderBook not found`, {
      extra: {
        orderBooks: JSON.stringify(orderBooks),
        loadingOrderBooks: loadingOrderBooks,
        orderBookError: error ?? 'No error',
        orderBookId,
      },
    })
  }

  const computeCumulative = (currentMarket: OrderBook, orders: OrdersSize[], side: 'ask' | 'bid'): OrderBookLine[] => {
    return orders.reduce((acc: OrderBookLine[], cur) => {
      if (!cur.price || !cur.size) return acc
      let cumulative: number
      if (acc.length > 0) {
        cumulative = acc[acc.length - 1].cumulative + Number(cur.size)
      } else {
        cumulative = Number(cur.size)
      }

      const updatedItem: OrderBookLine = {
        ...cur,
        cumulative,
        side,
        tickSize: currentMarket.tickSize,
      }

      acc.push(updatedItem)
      return acc
    }, [] as OrderBookLine[])
  }

  // Assuming you have asks and bids arrays
  const cumulativeAsks = useMemo(() => {
    if (!currentMarket) return []
    return computeCumulative(currentMarket, sells, 'ask')
  }, [sells, currentMarket])

  const cumulativeBids = useMemo(() => {
    if (!currentMarket) return []
    return computeCumulative(currentMarket, buys, 'bid')
  }, [buys, currentMarket])

  const asksFilled = useMemo(() => {
    if (cumulativeAsks) {
      // Array should always have 30 elements

      const filledArray = new Array(Math.max(30 - cumulativeAsks.length, 0)).fill(null)
      return [...cumulativeAsks, ...filledArray]
    }
    return []
  }, [cumulativeAsks])

  const bidsFilled = useMemo(() => {
    if (cumulativeBids) {
      // Array should always have 30 elements

      const filledArray = new Array(Math.max(30 - cumulativeBids.length, 0)).fill(null)
      return [...cumulativeBids, ...filledArray]
    }
    return []
  }, [cumulativeBids])

  return {
    loadingOrderBook: loadingOrderBooks,
    baseUID: loadingOrderBooks || !currentMarket ? '' : currentMarket.baseUID,
    quoteUID: loadingOrderBooks || !currentMarket ? '' : currentMarket.quoteUID,
    tickSize: loadingOrderBooks || !currentMarket ? 2 : currentMarket.tickSize,
    priceDisplayMultiplier: loadingOrderBooks || !currentMarket ? 1 : currentMarket.priceDisplayMultiplier,
    asks: asksFilled,
    cumulativeAsks,
    cumulativeBids,
    bids: bidsFilled,
    spread: poolOrdersSummary?.spread ?? 0,
    midPrice: poolOrdersSummary?.midPrice ?? 0,
    orderBookId,
  }
}
