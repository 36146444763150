import { lazy } from 'react'
import { authLoader } from '@/router/actions'
import { ProtectedRoute } from '@/router/protected-route'
import DefaultLayout from '@/components/Layouts/DefaultLayout'
import BlankLayout from '@/components/Layouts/BlankLayout'
import { Navigate, redirect } from 'react-router-dom'
import Bonds from '@/pages/bonds'
import Administration from '@/pages/administration'
import { AdministrationPermission } from '@/pages/administration/AdministrationPermission'
import { getAuth, signOut } from 'firebase/auth'
import { Chart } from '@/pages/chart'

const Trade = lazy(() => import('@/pages/trade'))
const TradeMiniPay = lazy(() => import('@/pages/minipay'))
const Pool = lazy(() => import('@/pages/pool-liquidity'))
const UserProfile = lazy(() => import('@/pages/user'))
const Portfolio = lazy(() => import('@/pages/portfolio'))
const Explorer = lazy(() => import('@/pages/explorer'))
const YieldManagement = lazy(() => import('@/pages/yield/yield-management'))
const YieldPool = lazy(() => import('src/pages/yield/yield-pool'))
const YieldLiquidity = lazy(() => import('src/pages/yield/yield-liquidity'))
const UserSelection = lazy(() => import('@/pages/useTypeSelection'))
const SignInPage = lazy(() => import('@/pages/auth/SignInPage'))
const SignUpPage = lazy(() => import('@/pages/auth/SignUpPage'))
const RecoverPassword = lazy(() => import('@/pages/auth/RecoverPasswordPage'))
const UpdatePassword = lazy(() => import('@/pages/auth/UpdatePasswordPage'))
const Error404 = lazy(() => import('@/pages/error/Error404'))

const routes = [
  {
    id: 'root',
    path: '/',
    element: (
      <ProtectedRoute>
        <DefaultLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        index: true,
        element: <Navigate to='/trade' />,
      },
      {
        path: '/trade',
        children: [
          {
            index: true,
            element: <Trade />,
          },
          {
            path: ':orderBookId',
            element: <Trade />,
          },
        ],
      },
      {
        path: '/user',
        children: [
          {
            index: true,
            element: <div>USER INDEX</div>,
          },
          {
            path: '/user/profile',
            element: <UserProfile />,
          },
          {
            path: '/user/settings',
            element: <UserProfile />,
          },
        ],
      },
      {
        path: '/pool',
        children: [
          {
            index: true,
            element: <Pool />,
          },
          {
            path: ':orderBookId',
            element: <Pool />,
          },
        ],
      },
      {
        path: '/bonds',
        children: [
          {
            path: ':orderBookId',
            element: <Bonds />,
          },
        ],
      },
      {
        path: '/administration',
        children: [
          {
            index: true,
            element: (
              <AdministrationPermission>
                <Administration />
              </AdministrationPermission>
            ),
          },
        ],
      },
      {
        path: '/yield',
        children: [
          {
            index: true,
            element: <YieldManagement />,
          },
        ],
      },
      {
        path: '/yield-pool',
        children: [
          {
            index: true,
            element: <YieldPool />,
          },
        ],
      },
      {
        path: '/yield-liquidity',
        children: [
          {
            index: true,
            element: <YieldLiquidity />,
          },
        ],
      },
      {
        path: '/userSelection',
        children: [
          {
            index: true,
            element: <UserSelection />,
          },
        ],
      },

      // dashboard
      {
        id: 'portfolio',
        path: '/portfolio',
        // loader: rootLoader,
        children: [
          {
            index: true,
            element: <Portfolio />,
            // loader: eventLoader,
          },
        ],
      },
      // {
      //   path: '/margin',
      //   element: <DefaultLayout />,
      //   children: [
      //     {
      //       index: true,
      //       element: (
      //         <Navigate to={`/margin/${import.meta.env.VITE_APP_DEFAULT_ORDERBOOK_ID ?? 'AU3CB0261394-CA3CB0461596'}`} />
      //       ),
      //     },
      //     {
      //       path: ':orderBookId',
      //       element: (
      //         <ProtectedRoute>
      //           <Margin />
      //         </ProtectedRoute>
      //       ),
      //     },
      //   ],
      // },
      {
        id: 'explorer',
        path: '/explorer/*',
        // loader: rootLoader,
        children: [
          {
            index: true,
            element: <Explorer />,
          },
        ],
      },
      {
        path: '/',
        children: [
          // {
          //   index: true,
          //   element: <UserRoleRoute />,
          // },
          // {
          //   path: '*',
          //   element: <Navigate to='/trade' />,
          // },
        ],
      },
      {
        path: 'protected',
        loader: () => redirect('/'),
        Component: Trade,
      },
      {
        id: 'unknown',
        path: '*',
        element: <Navigate to='/' />,
      },
    ],
  },
  {
    path: '/minipay',
    Component: BlankLayout,
    children: [
      {
        index: true,
        element: <TradeMiniPay />,
      },
      {
        path: ':orderBookId',
        element: <TradeMiniPay />,
      },
    ],
  },
  {
    path: 'chart',
    Component: BlankLayout,
    children: [
      {
        path: ':orderBookId',
        element: <Chart />,
      },
      {
        index: true,
        element: <Navigate to='/' />,
      },
    ],
  },
  {
    path: 'auth',
    Component: BlankLayout,
    children: [
      {
        index: true,
        path: '/auth/sign-in',
        element: <SignInPage />,
        // loader: authLoader,
      },
      {
        path: '/auth/sign-up',
        element: <SignUpPage />,
        // loader: authLoader,
      },
      {
        path: '/auth/recover',
        element: <RecoverPassword />,
        loader: authLoader,
      },
      {
        path: '/auth/update-password',
        element: <UpdatePassword />,
        loader: authLoader,
      },
    ],
  },

  {
    id: 'error-404',
    path: '*',
    element: <BlankLayout />,
    // loader: rootLoader,
    children: [
      {
        index: true,
        element: <Error404 />,
        // loader: eventLoader,
      },
    ],
  },
  {
    path: '/logout',
    async action() {
      await signOut(getAuth())
      return redirect('/')
    },
  },
]

export { routes }
