import { getSdk } from '@/graphql/generated/graphql-request'
import { queryClient } from '@/services/api'
import { graphQLClient } from '@/services/graphql'
import { createNotification, NotificationStatus, NotificationType } from '@/utils/notificationUtils'
import { useMutation } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useTransactionReceipt } from 'wagmi'
import EventEmitter from 'eventemitter3'
import { Connection } from '@solana/web3.js'

interface Deposit {
  chainID: number | string
  txHex: string
  fromHex: string
  toHex: string
  tokenContractHex: string
  amount: string
  network: 'eth' | 'solana'
}

export const depositEventEmitter = new EventEmitter()

export const addDepositQueue = (deposit: Deposit) => {
  const deposits = JSON.parse(window.localStorage.getItem('external-deposit') || '[]')
  window.localStorage.setItem('external-deposit', JSON.stringify([...deposits, deposit]))
}

const useHandleDeposit = () => {
  const [deposit, setDeposit] = useState<Deposit>()
  const result = useTransactionReceipt({
    hash: deposit?.txHex as any,
    chainId: deposit?.chainID as any,
  })

  const depositMutation = useMutation({
    mutationFn: () =>
      getSdk(graphQLClient)
        .ExternalDeposit({
          deposit: {
            chainID: String(deposit?.chainID || 1),
            txHex: deposit?.txHex || '',
            fromHex: deposit?.fromHex || '',
            toHex: deposit?.toHex || '',
            tokenContractHex: deposit?.tokenContractHex || '',
            network: deposit?.network || 'eth',
          },
        })
        .then(() => {
          // params.selectedAsset, params.text, params.amount, params.isAdding
          createNotification(
            'Deposit',
            `You successfully deposited ${deposit?.amount} USDC on Dora. You can start trading now.`,
            NotificationType.SIMPLE,
          )
          depositEventEmitter.emit('deposit-success', deposit)
          console.log('emitted')
        })
        .catch((err) => {
          const message = err?.response?.errors[0]?.message ?? 'Error on ExternalDeposit'
          createNotification('Error on ExternalDeposit', message, NotificationType.SIMPLE, NotificationStatus.ERROR)
        }),
    onSettled: () => {
      const deposits: Deposit[] = JSON.parse(window.localStorage.getItem('external-deposit') || '[]')
      const handlingDepositIndex = deposits.findIndex((d) => d.txHex === deposit?.txHex)
      window.localStorage.setItem(
        'external-deposit',
        JSON.stringify([...deposits.slice(0, handlingDepositIndex), ...deposits.slice(handlingDepositIndex + 1)]),
      )
      queryClient.invalidateQueries({ queryKey: ['USER_BALANCES'] }).finally()
    },
  })

  useEffect(() => {
    const interval = setInterval(() => {
      const deposits: Deposit[] = JSON.parse(window.localStorage.getItem('external-deposit') || '[]')
      if (deposits.length) {
        setDeposit(deposits[0])
      }
    }, 5000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    if (deposit?.network === 'solana' && !!deposit?.txHex) {
      console.log(deposit)
      const connection = new Connection('https://mainnet.helius-rpc.com/?api-key=e218af64-48b5-4a1a-b52a-5728fbe3f4c4')
      connection.getLatestBlockhash().then((latestBlockHash) => {
        console.log(
          { latestBlockHash },
          {
            signature: deposit.txHex,
            blockhash: latestBlockHash.blockhash,
            lastValidBlockHeight: latestBlockHash.lastValidBlockHeight,
          },
        )
        connection
          .confirmTransaction(
            {
              signature: deposit.txHex,
              blockhash: latestBlockHash.blockhash,
              lastValidBlockHeight: latestBlockHash.lastValidBlockHeight,
            },
            'finalized',
          )
          .then(() => {
            console.log('mutate')
            depositMutation.mutate()
          })
          .catch((err) => {
            console.log(err)
            if (err.message.includes('expired')) {
              depositMutation.mutate()
            }
          })
      })
    }
  }, [deposit?.txHex])

  useEffect(() => {
    if (!!result.data?.status) {
      if (result.data?.status === 'success') {
        depositMutation.mutate()
      } else if (result.data?.status === 'reverted') {
        createNotification(
          'Error on ExternalDeposit',
          'Transaction is reverted',
          NotificationType.SIMPLE,
          NotificationStatus.ERROR,
        )
      }
    }
  }, [result.data?.status, result.data?.transactionHash])
}

export default useHandleDeposit
