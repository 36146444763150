import { useQuery } from '@tanstack/react-query'
import { getSdk, OrderBook } from '@/graphql/generated/graphql-request'
import { graphQLClient } from '@/services/graphql'
import { useQueryTime } from '@/hooks/useQueryTime'
import { createNotification, NotificationStatus, NotificationType } from '@/utils/notificationUtils'

export const useOrderBooks = () => {
  const {
    isLoading: loadingOrderBooks,
    data: orderBooks,
    error,
    status,
  } = useQuery({
    queryKey: ['ORDERBOOKS'],
    retry: 5,
    queryFn: () =>
      getSdk(graphQLClient)
        .GetAllOrderBooks()
        .then((result) => result.getAllOrderBooks as OrderBook[])
        .catch((err) => {
          const message = err?.response?.errors[0]?.message ?? 'Error on GetAllOrderBooks'
          createNotification('Error on GetAllOrderBooks', message, NotificationType.SIMPLE, NotificationStatus.ERROR)
        }),
  })
  useQueryTime(`['ORDERBOOKS']`, status)
  return { loadingOrderBooks, orderBooks: orderBooks ?? [], error }
}
