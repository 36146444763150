import { Link } from 'react-router-dom'
import { useAppEnv } from '@/hooks/useAppEnv'

interface FooterProps {
  isApiAvailable: boolean
}

const Footer = ({ isApiAvailable }: FooterProps) => {
  const { appEnv } = useAppEnv()
  return (
    <div
      id='dora-footer'
      className='h-[24px] flex w-full justify-between z-50 absolute bottom-0 bg-panel border border-x-0 border-b-0 border-panel-border px-2'
    >
      <div className='z-10 sticky bottom-0 bg-panel left-0 dark:text-white-dark text-center ltr:sm:text-left rtl:sm:text-right mt-auto'>
        © {new Date().getFullYear()}. DORA All rights reserved.
      </div>

      {import.meta.env.VITE_VERCEL_GIT_COMMIT_SHA && (
        <div className={'flex flex-row gap-1 items-center mt-auto'}>
          <div className={'text-white-dark'}>Version:</div>
          <Link
            target={'_blank'}
            to={`https://github.com/dora-network/frontend-bonds/commit/${import.meta.env.VITE_VERCEL_GIT_COMMIT_SHA}`}
            className={'text-primary'}
          >
            <div>{`${import.meta.env.VITE_VERCEL_GIT_COMMIT_SHA}`.substring(0, 7)}</div>
          </Link>
        </div>
      )}

      <div className='items-center flex gap-2 z-10 sticky bottom-0 right-0 dark:text-white-dark text-center ltr:sm:text-left rtl:sm:text-right mt-auto'>
        <div
          style={{
            width: '0.4rem',
            height: '0.4rem',
            borderRadius: '50%',
            backgroundColor: isApiAvailable ? 'green' : 'red',
          }}
        ></div>
        <h2>API </h2>
      </div>
    </div>
  )
}

export default Footer
