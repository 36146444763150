import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormEvent, useState } from 'react'
import { removeLettersForInput } from '@/utils/inputUtils'
import { useAllAssets } from '@/hooks/useAsset'
import { Asset, AssetType, getSdk } from '@/graphql/generated/graphql-request'
import { useMutation } from '@tanstack/react-query'
import { graphQLClient } from '@/services/graphql'
import { createNotification, NotificationStatus, NotificationType } from '@/utils/notificationUtils'
import { formatNumber, removeFormatNumber } from '@/utils/numberUtils'
import { BalanceWithAvailability, groupBalancesByAssetID } from '@/utils/balancesUtils'
import { useUserBalances } from '@/hooks/useUserBalances'
import { MAX_AMOUNT_ADDING_BALANCE, PRICE_DECIMALS } from '@/utils/constants'

type Inputs = {
  amount: string
  asset: string
}

const validationSchema = z.object({
  amount: z.string(),
  asset: z.string().min(1, 'Please select an asset'),
})

type ValidationSchema = z.infer<typeof validationSchema>

export const AddRemoveUserBalance = () => {
  const { balances } = useUserBalances()
  const { allAssets } = useAllAssets()
  const [selectedAsset, setSelectedAsset] = useState(allAssets[0]) // Initialize the selected option

  const availableBalance = balances?.available ?? []
  const lockedBalance = balances?.locked ?? []

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ValidationSchema>({
    resolver: zodResolver(validationSchema),
  })

  const onSelectionChange = (e: FormEvent<HTMLSelectElement>) => {
    const selectedOption = e.currentTarget.value
    const asset = allAssets.find((asset: Asset) => {
      return asset?.uid === selectedOption
    })
    if (!asset) return
    setSelectedAsset(asset)
  }

  const addBalanceMutation = useMutation({
    mutationFn: ({ amount, assetID }: { amount: string; assetID: string }) =>
      getSdk(graphQLClient)
        .AddBalances({
          balanceToAdd: {
            amount: amount,
            assetID: assetID,
          },
        })
        .then(() => {
          // params.selectedAsset, params.text, params.amount, params.isAdding
          createNotification(
            'Add Balance',
            {
              selectedAsset: selectedAsset,
              text: 'Balance added successfully',
              amount: amount,
              isAdding: true,
            },
            NotificationType.ADD_REMOVE_BALANCE,
          )
        })
        .catch((err) => {
          const message = err?.response?.errors[0]?.message ?? 'Error on AddBalances'
          createNotification('Error on AddBalances', message, NotificationType.SIMPLE, NotificationStatus.ERROR)
        }),
    onSettled: () => {},
  })

  const removeBalanceMutation = useMutation({
    mutationFn: ({ amount, assetID }: { amount: string; assetID: string }) =>
      getSdk(graphQLClient)
        .RemoveBalances({
          balanceToRmv: {
            amount: amount,
            assetID: assetID,
          },
        })
        .then(() => {
          createNotification(
            'Remove Balance',
            {
              selectedAsset: selectedAsset,
              text: 'Balance removed successfully',
              amount: amount,
              isAdding: false,
            },
            NotificationType.ADD_REMOVE_BALANCE,
          )
        })
        .catch((err) => {
          const message = err?.response?.errors[0]?.message ?? 'Error on RemoveBalances'
          createNotification('Error on RemoveBalances', message, NotificationType.SIMPLE, NotificationStatus.ERROR)
        }),
    onSettled: () => {},
  })

  const onAdd = async (data: Inputs) => {
    try {
      const { amount, asset } = data
      const unformatted = removeFormatNumber(amount)
      const decimals = allAssets.find((currentAsset: Asset) => currentAsset?.uid === asset)?.decimals ?? 0
      const calculatedAmount = Number(unformatted ?? 0) * Math.pow(10, decimals)

      // Perform action for adding asset balance here
      addBalanceMutation.mutate({
        amount: calculatedAmount.toString(),
        assetID: asset,
      })

      console.log('Adding balance', unformatted, calculatedAmount, decimals)
    } catch (error) {
      console.error(error)
    }
  }

  const onRemove = async (data: Inputs) => {
    try {
      const { amount, asset } = data
      const unformatted = removeFormatNumber(amount)
      const decimals = allAssets.find((currentAsset: Asset) => currentAsset?.uid === asset)?.decimals ?? 0
      const calculatedAmount = Number(unformatted ?? 0) * Math.pow(10, decimals)

      // Perform action for removing asset balance here
      removeBalanceMutation.mutate({
        amount: calculatedAmount.toString(),
        assetID: asset,
      })

      console.log('Removing balance', unformatted, calculatedAmount, decimals)
    } catch (error) {
      console.error(error)
    }
  }

  const handleInputChange = (e: FormEvent<HTMLInputElement>) => {
    const inputValue = e.currentTarget.value
    if (Number(removeFormatNumber(inputValue)) > MAX_AMOUNT_ADDING_BALANCE) {
      setValue('amount', removeLettersForInput(MAX_AMOUNT_ADDING_BALANCE, PRICE_DECIMALS))
      return
    }
    const sanitizedInput = removeLettersForInput(inputValue, PRICE_DECIMALS)
    setValue('amount', sanitizedInput)
  }

  const getBondOrCurrencyBalances = (isBond: boolean) => {
    const result = []
    const groupedBalances = groupBalancesByAssetID(availableBalance, lockedBalance)

    for (const assetID in groupedBalances) {
      const balances = groupedBalances[assetID]

      const availableAmount = balances
        .filter((balance: BalanceWithAvailability) => balance.available)
        .reduce((sum, balance) => sum + Number(balance.amount), 0)

      const lockedAmount = balances
        .filter((balance: BalanceWithAvailability) => !balance.available)
        .reduce((sum, balance) => sum + Number(balance.amount), 0)

      const asset = allAssets.find(
        (asset: Asset) =>
          asset?.uid === assetID && (isBond ? asset.type === AssetType.Bond : asset.type === AssetType.Currency),
      )

      if (asset)
        result.push(
          <div key={'asset_balance' + assetID} className='flex flex-row justify-between w-full mt-2 gap-2'>
            <div className='text-white'>{asset?.symbol}</div>
            <div className='flex flex-row justify-end w-full gap-8'>
              <div className='font-semibold text-success'>
                {formatNumber(availableAmount / Math.pow(10, asset?.decimals ?? 0), PRICE_DECIMALS)}
              </div>
              <div className='font-semibold text-danger'>
                {formatNumber(lockedAmount / Math.pow(10, asset?.decimals ?? 0), PRICE_DECIMALS)}
              </div>
            </div>
          </div>,
        )
    }
    return result
  }

  const getPoolBalances = () => {
    const result = []
    const groupedBalances = groupBalancesByAssetID(availableBalance, lockedBalance)
    for (const assetID in groupedBalances) {
      const balances = groupedBalances[assetID]

      const availableAmount = balances
        .filter((balance: BalanceWithAvailability) => balance.available)
        .reduce((sum, balance) => sum + Number(balance.amount), 0)

      const lockedAmount = balances
        .filter((balance: BalanceWithAvailability) => !balance.available)
        .reduce((sum, balance) => sum + Number(balance.amount), 0)

      const asset = allAssets.find((asset: Asset) => asset?.uid === assetID && asset.type === AssetType.PoolShare)

      if (asset)
        result.push(
          <div key={'pool_balance' + assetID} className='flex flex-row justify-between w-full mt-2 gap-2'>
            <div className='text-white'>{asset?.symbol}</div>
            <div className='flex flex-row justify-end w-full gap-8'>
              <div className='font-semibold text-success'>
                {formatNumber(availableAmount / Math.pow(10, asset?.decimals ?? 0), PRICE_DECIMALS)}
              </div>
              <div className='font-semibold text-danger'>
                {formatNumber(lockedAmount / Math.pow(10, asset?.decimals ?? 0), PRICE_DECIMALS)}
              </div>
            </div>
          </div>,
        )
    }
    return result
  }

  const getBalances = () => {
    return (
      <div key={'getBalances'}>
        <div className='flex justify-between'>
          <div>Asset Balances</div>
          <div className='flex gap-4'>
            <div>Available</div>
            <div>Locked</div>
          </div>
        </div>
        {getBondOrCurrencyBalances(true)}
        <div className='mt-8'>Currency Balances</div>
        {getBondOrCurrencyBalances(false)}
        <div className='mt-8'>Pool Balances</div>
        {getPoolBalances()}
      </div>
    )
  }

  return (
    <div className='w-full flex flex-row gap-2'>
      <div className='w-full max-w-md panel dark:bg-card flex flex-col flex-grow h-full'>
        <h1 className={'dark:text-white text-lg pb-1 font-semibold'}>Add or remove user balance</h1>
        <div className='max-w-lg break-words text-sm'>
          This will add or remove balance of a specific asset to the user's balance
        </div>
        <div className='max-w-lg break-words text-sm mt-2'>
          Input the amount in dollar/USDY values - Example: If you want
        </div>
        <div className='max-w-lg break-words text-sm mt-2'>
          to fund the user with 1 million, please enter 1000000. If you want to fund the user with 5150.50, please enter
          5150.50
        </div>
        <form>
          {/* Dropdown for the selection field */}
          <div className={'mt-8'}>
            <div className='relative flex flex-col'>
              <div className='flex items-center'>
                <div className='py-1 min-w-[100px] text-left'>Asset</div>
                <div className='flex flex-col items-end justify-end'>
                  <select
                    {...register('asset', { required: true })}
                    value={selectedAsset?.uid ?? 'Select an asset'}
                    onChange={(e) => onSelectionChange(e)}
                    className='relative border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                  >
                    {allAssets.map((asset: Asset) => (
                      <option key={asset?.uid} value={asset?.uid}>
                        {asset?.symbol}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <span className='text-red-500 text-end' style={{ minHeight: '1rem' }}>
                {errors.asset ? errors.asset.message : '\u00a0'}
              </span>
            </div>
            <div className='relative flex flex-col'>
              <div className='flex justify-between items-center top-0'>
                <div className='py-1 min-w-[100px] text-left'>Amount</div>
                <input
                  id={'amount'}
                  {...register('amount', { required: true })}
                  onInput={(e) => handleInputChange(e)}
                  className='border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                />
              </div>
              <span className='text-red-500 text-end w-full' style={{ minHeight: '1rem' }}>
                {errors.amount ? errors.amount.message : '\u00a0'}
              </span>
            </div>
          </div>
          <div className='mt-8 flex flex-row gap-2 '>
            <button
              type='button'
              onClick={handleSubmit(onAdd)}
              //disabled={!isValid || !isDirty}
              className='btn bg-gradient-to-r from-secondary to-primary hover:from-primary hover:to-secondary text-white w-full border-0 uppercase shadow-[0_10px_20px_-10px_rgba(67,97,238,0.44)] '
            >
              Add
            </button>
            <button
              type='button'
              onClick={handleSubmit(onRemove)}
              //disabled={!isValid || !isDirty}
              className='btn bg-gradient-to-r from-secondary to-primary hover:from-primary hover:to-secondary text-white w-full border-0 uppercase shadow-[0_10px_20px_-10px_rgba(67,97,238,0.44)] '
            >
              Remove
            </button>
          </div>
        </form>
      </div>

      <div className='w-full max-w-md panel dark:bg-card flex flex-col flex-grow h-full'>
        <h1 className={'dark:text-white text-lg pb-4 font-semibold'}>Current balances</h1>
        {balances ? <div>{getBalances()}</div> : <div>No balances</div>}
      </div>
    </div>
  )
}
