import { Asset, OrderBook } from '@/graphql/generated/graphql-request'
import { createNotification, NotificationType } from '@/utils/notificationUtils'

interface BondDetailsInfoProps {
  baseAsset: Asset
  orderBook: OrderBook | undefined
  top?: boolean
}

export const BondDetailsInfo = ({ baseAsset, orderBook, top }: BondDetailsInfoProps) => {
  if (!baseAsset || !orderBook) {
    return <div>No pair selected</div>
  }

  return (
    <div
      className={`w-full h-fit flex flex-col col-start-3 col-span-1 ${top ? 'justify-start' : 'justify-center'} items-start gap-4 select-text`}
    >
      <div
        className={'w-full text-end font-bold text-xl pb-4 cursor-pointer flex flex-row'}
        onClick={() => {
          if (!baseAsset?.bond?.cusip) return

          navigator.clipboard
            .writeText(baseAsset?.bond?.cusip)
            .finally(() =>
              createNotification(
                'Copied ',
                `CUSIP ${baseAsset?.bond?.cusip} has been copied to clipboard`,
                NotificationType.DISPOSABLE,
              ),
            )
        }}
      >
        <div className={'hover:text-white-dark'}>CUSIP: {baseAsset?.bond?.cusip ?? 'n/a'}</div>
      </div>
      <div className={'w-full flex flex-row px-1 justify-between border-b border-dark pb-2'}>
        <div className={'text-white-dark font-semibold'}>Ticker:</div>
        <div className={'font-semibold'}>{baseAsset?.bond?.ticker ?? 'n/a'}</div>
      </div>

      <div className={'w-full flex flex-row px-1 justify-between border-b border-dark pb-2'}>
        <div className={'text-white-dark font-semibold'}>Type:</div>
        <div className={'font-semibold'}>{baseAsset?.bond?.assetBondType ?? 'n/a'}</div>
      </div>

      <div className={'w-full flex flex-row px-1 justify-between border-b border-dark pb-2'}>
        <div className={'text-white-dark font-semibold'}>Tick Size:</div>
        <div className={'font-semibold'}>${orderBook?.tickSize ?? 'n/a'}</div>
      </div>

      <div className={'w-full flex flex-row px-1 justify-between border-b border-dark pb-2'}>
        <div className={'text-white-dark font-semibold'}>Step Size:</div>
        <div className={'flex flex-row gap-2'}>
          <div className={'font-semibold'}>{orderBook?.tickSize ?? 'n/a'}</div>
          <div className={'text-white rounded-md bg-dark font-semibold px-2'}>{baseAsset?.symbol ?? 'n/a'}</div>
        </div>
      </div>

      <div className={'w-full flex flex-row px-1 justify-between border-b border-dark pb-2'}>
        <div className={'text-white-dark font-semibold'}>Minimum Order Size:</div>
        <div className={'flex flex-row gap-2'}>
          <div className={'font-semibold'}>{orderBook?.tickSize ?? 'n/a'}</div>
          <div className={'text-white rounded-md bg-dark font-semibold px-2'}>{baseAsset?.symbol ?? 'n/a'}</div>
        </div>
      </div>

      <div className={'w-full flex flex-row px-1 justify-between border-b border-dark pb-2'}>
        <div className={'text-white-dark font-semibold'}>Maximum Leverage:</div>
        <div className={'flex flex-row gap-2'}>
          <div className={'font-semibold'}>10.00x</div>
        </div>
      </div>

      <div className={'w-full flex flex-row px-1 justify-between border-b border-dark pb-2'}>
        <div className={'text-white-dark font-semibold'}>Maintenance Margin Fraction:</div>
        <div className={'flex flex-row gap-2'}>
          <div className={'font-semibold'}>7.000%</div>
        </div>
      </div>

      <div className={'w-full flex flex-row px-1 justify-between pb-2'}>
        <div className={'text-white-dark font-semibold'}>Initial Margin Fraction:</div>
        <div className={'flex flex-row gap-2'}>
          <div className={'font-semibold'}>10.000%</div>
        </div>
      </div>
    </div>
  )
}
