import { gql } from 'graphql-request'

export const USER_ORDERS_SUBSCRIPTION = gql`
  subscription UserOrderChanged($orderBookId: String!, $userUid: String!) {
    userOrderChanged(orderBookID: $orderBookId, userUID: $userUid) {
      orderID
      orderBookID
      userUID
      createdAt
      updatedAt
      type
      state
      assetInID
      assetOutID
      amountIn
      amountOut
      inFilled
      outFilled
      price
      inverseLeverage
      sortAmountIn
      sortAmountOut
      sortInFilled
      sortOutFilled
      sortRemainingAmountIn
      advancedType
      triggerPrice
      modifiers
      transitionInfo {
        orderState
        message
      }
    }
  }
`

export const CANDLE_UPDATE_SUBSCRIPTION = gql`
  subscription CandlesUpdated($marketId: String!, $resolution: String!) {
    candlesUpdated(marketID: $marketId, resolution: $resolution) {
      close
      high
      low
      open
      time
      volume
    }
  }
`
