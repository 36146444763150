import { useEffect, useState } from 'react'
import { wsClient } from '@/services/graphql'
import { ORDERBOOK_SUBSCRIPTION } from '@/services/graphql/subscriptions/orderbook'
import { OrdersSizeResponse } from '@/graphql/generated/graphql-request'
import { orderBy } from 'lodash'
import { queryClient } from '@/services/api'

export const useOrderBookSubscription = (orderBookId: string) => {
  const [, setForceUpdate] = useState(0) // State to force update

  useEffect(() => {
    const subscription = wsClient.subscribe(
      {
        variables: {
          orderBookId: orderBookId,
        },
        operationName: 'OrderBookUpdated',
        query: ORDERBOOK_SUBSCRIPTION,
      },
      {
        next: ({ data }: { data: { orderBookUpdated: OrdersSizeResponse } | null }) => {
          if (!data) return

          const prevOrders = { ...queryClient.getQueryData<OrdersSizeResponse>(['ORDER_BOOK_ORDERS', orderBookId]) }
          let prevBuys = prevOrders?.buys ?? []
          let prevSells = prevOrders?.sells ?? []

          if (data.orderBookUpdated.buys.length !== 0) {
            for (let i = 0; i < data.orderBookUpdated.buys.length; i++) {
              const buy = data.orderBookUpdated.buys[i]
              const index = prevBuys.findIndex((b) => b.price === buy.price)
              if (index !== -1) {
                prevBuys[index] = buy
              } else {
                prevBuys.push(buy)
              }
            }

            prevBuys = [...orderBy(prevBuys, 'price', 'desc')]
          }
          if (data.orderBookUpdated.sells.length !== 0) {
            for (let i = 0; i < data.orderBookUpdated.sells.length; i++) {
              const sell = data.orderBookUpdated.sells[i]
              const index = prevSells.findIndex((s) => s.price === sell.price)
              if (index !== -1) {
                prevSells[index] = sell
              } else {
                prevSells.push(sell)
              }
            }

            prevSells = [...orderBy(prevSells, 'price')]
          }

          queryClient.setQueryData(['ORDER_BOOK_ORDERS', orderBookId], { buys: prevBuys, sells: prevSells })
          setForceUpdate((prev) => prev + 1) // Force update
        },
        error: (err) => {
          console.log('useOrderBookSubscription subscription error', err)
        },
        complete: () => {},
      },
    )

    // Cleanup function to unsubscribe
    return () => {
      console.log('useOrderBookSubscription - Unsubscribing from orderBook subscription')
      subscription()
    }
  }, [orderBookId])
}
